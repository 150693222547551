import React from "react";
import { StaticQuery, graphql } from "gatsby";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return <div className={className} style={{ ...style }} onClick={onClick} />;
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return <div className={className} style={{ ...style }} onClick={onClick} />;
}

export default function ClimateFacts() {
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
    };
    return (
        <StaticQuery
            query={graphql`
                query FactsQuery {
                    allSanityFacts {
                        edges {
                            node {
                                _id
                                title
                                description {
                                    children {
                                        text
                                    }
                                }
                            }
                        }
                    }
                }
            `}
            render={(data) => (
                <Slider {...settings}>
                    {data.allSanityFacts.edges.map((edge) => (
                        <div>
                            <h3>{edge.node.title}</h3>
                            {edge.node.description ? (
                                <p>
                                    {edge.node.description.map((block) =>
                                        block.children.map((content, i) =>
                                            content.text ? <p key={i}>{content.text}</p> : ""
                                        )
                                    )}
                                </p>
                            ) : null}
                        </div>
                    ))}
                </Slider>
            )}
        />
    );
}

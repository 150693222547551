import React from "react";
import { graphql } from "gatsby";

import FormattedText from "../components/portableText";

export const fragment = graphql`
    fragment FightingClimateChange on SanityFightingClimateChange {
        _key
        _type
        _rawContent
        content {
            children {
                text
            }
        }
    }
`;

const FightingClimateChangeTogether = (props) => {
    return (
        <div key={props.key} className="relative -top-10">
            <div className="headline-title fcc">
                <span className="impact-title">Fighting</span>
                <span className="impact-title">Climate</span>
                <span className="impact-title">Change</span>
                <span className="impact-title">Together</span>
            </div>
            {props._rawContent ? (
                <div className="container w-full sm:w-4/5 md:w-1/2 mx-auto text-center mb-12">
                    <FormattedText blocks={props._rawContent} />
                </div>
            ) : null}
        </div>
    );
};

export default FightingClimateChangeTogether;

import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";

const Banner = (props) => {
    return (
        <div id="banner" role="banner">
            <GatsbyImage image={props.imageData} alt={props.alt} />
        </div>
    );
};

export default Banner;

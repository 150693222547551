import React from "react";
import { graphql } from "gatsby";

import FormattedText from "../components/portableText";

export const fragment = graphql`
    fragment TextBlockSection on SanityTextBlock {
        _key
        _type
        id
        _rawRichTextBlock
        richTextBlock {
            _key
            _type
            children {
                text
            }
        }
    }
`;

const TextBlock = (props) => {
    return (
        <>
            <div
                key={props.key}
                id={props.id ? props.id : null}
                className="container xl:w-5/6 mx-auto mb-12 p-6"
            >
                <FormattedText blocks={props._rawRichTextBlock} />
            </div>
        </>
    );
};

export default TextBlock;

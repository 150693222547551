import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import ClimateSlider from "../components/climatefacts";

export const fragment = graphql`
    fragment ClimateFacts on SanityClimateFacts {
        _key
        _type
        id
        image {
            asset {
                gatsbyImageData
            }
        }
    }
`;

const ClimateFactsSlider = (props) => {
    console.log(props);
    return (
        <div
            key={props.key}
            className="slider climate-change container xl:w-5/6 mx-auto flex flex-wrap py-24"
            id="climate-facts"
        >
            <div className="relative title w-full">
                <h2 className="relative">
                    <span className="impact-title">Climate</span>
                    <span className="impact-title">Change Facts</span>
                </h2>
            </div>
            <div className="slider-hero w-full sm:w-1/2">
                <GatsbyImage image={props.image.asset.gatsbyImageData} />
            </div>
            <div className="w-full sm:w-1/2 z-10 flex">
                <div className="slider-container__box">
                    <ClimateSlider />
                </div>
            </div>
        </div>
    );
};

export default ClimateFactsSlider;

import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import StyledLink from "./styledlink";
import FormattedText from "./portableText";

const IntroWithBackground = (props) => {
    console.log(props);
    return (
        <div id={props.id ? props.id : null} className="py-12 mb-12 intro theme-bg md:pt-24">
            <div className="container flex flex-wrap p-6 mx-auto intro-container xl:w-5/6">
                <div className="w-full p-6 sm:w-1/2 md:w-7/12">
                    <h1>{props.title}</h1>
                    <FormattedText blocks={props.content} />
                    {props.buttonText ? (
                        <StyledLink linkText={props.buttonText} link={props.buttonLink} />
                    ) : null}
                </div>
                {props.image ? (
                    <div className="flex justify-center w-full sm:w-1/2 md:w-5/12 align-center">
                        <GatsbyImage image={props.image} />
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export default IntroWithBackground;

import React from "react";
import { graphql } from "gatsby";
import WorkshopSlides from "../components/workshopSlides";
import StyledLink from "../components/styledlink";

export const fragment = graphql`
    fragment WorkshopSlider on SanityWorkshopSlider {
        _key
        _type
        id
        workshopIntro
    }
`;

const WorkshopSlider = (props) => {
    return (
        <div key={props.key} className="slider workshop-slider flex flex-wrap mb-12 mt-12 sm:mt-32">
            <div className="container mx-auto">
                <div className="relative title w-full">
                    <h2 className="relative top-0">
                        <span className="impact-title">Workshops</span>
                    </h2>
                </div>
                <div className="flex flex-wrap">
                    <div className="w-full md:w-2/3 order-2 md:order-1 p-6">
                        <p>{props.workshopIntro}</p>
                    </div>
                    <div className="w-full md:w-1/3 flex justify-end order-1 md:order-2">
                        <StyledLink link="/workshops" linkText="See all" />
                    </div>
                </div>
            </div>

            <div className="w-full">
                <div className="workshop-slider-container__box">
                    <WorkshopSlides />
                </div>
            </div>
        </div>
    );
};

export default WorkshopSlider;

import React from "react";
import { graphql } from "gatsby";

import Header from "../components/header";
import Layout from "../layout/layout";
import Banner from "../components/banner";
import EngageForChange from "../components/engageForChange";
import IntroWithBackground from "../components/introBg";
import TextBlock from "../components/textblock";
import Image from "../components/image";
import TextImgR from "../components/textImgR";
import ImgTwoColContent from "../components/imgTwoColContent";
import Carousel from "../components/carousel";
import UnorderedList from "../components/unorderedlist";
import ClimateFactsSlider from "../components/climateFactsSlider";
import EnterCompetition from "../components/enterCompetition";
import WorkshopSlider from "../components/workshopSlider";
import Curator from "../components/curator";
import FightingClimateChange from "../components/fightingClimateChange";
import HelpSlider from "../components/helpSlider";
import SEO from "../components/seo";

export const query = graphql`
    query ($slug: String) {
        sanityPage(page_url: { current: { eq: $slug } }) {
            page_title
            bannerImg {
                asset {
                    gatsbyImageData
                }
            }
            page_url {
                current
            }
            pageIntro {
                _rawContent
                content {
                    children {
                        text
                    }
                }
                id
                title
                image {
                    asset {
                        gatsbyImageData
                    }
                }
                link {
                    buttonLink
                    buttonTitle
                }
            }
            sections {
                __typename
                ...Header
                ...TextBlockSection
                ...ImageBlock
                ...TextImgR
                ...ImgTwoColContent
                ...Slider
                ...UnorderedListSection
                ...EnterCompetition
                ...FightingClimateChange
                ...ClimateFacts
                ...WorkshopSlider
                ...FactsSlider
            }
        }
    }
`;

const PageTemplate = ({ data }) => {
    const sections = data.sanityPage.sections;
    const intro = data.sanityPage.pageIntro;
    let imageData = data.sanityPage.pageIntro.image
        ? data.sanityPage.pageIntro.image.asset.gatsbyImageData
        : null;
    let introLink = intro.link ? intro.link.buttonLink : null;
    let introButton = intro.link ? intro.link.buttonTitle : null;
    return (
        <>
            <Layout>
                <SEO
                    canonical={data.sanityPage.page_url.current}
                    title={data.sanityPage.page_title}
                />
                <Banner
                    imageData={data.sanityPage.bannerImg.asset.gatsbyImageData}
                    alt={data.sanityPage.page_title}
                />
                <EngageForChange />
                <main id="main">
                    {intro ? (
                        <IntroWithBackground
                            id={intro.id}
                            title={intro.title}
                            content={intro._rawContent}
                            buttonLink={introLink}
                            buttonText={introButton}
                            image={imageData}
                        />
                    ) : null}
                    <div>
                        {sections.map((section) => {
                            const typeName = section.__typename;

                            switch (typeName) {
                                case "SanityHeader":
                                    return <Header key={section._key} {...section} />;
                                case "SanityTextBlock":
                                    return <TextBlock key={section._key} {...section} />;
                                case "SanityImage":
                                    return <Image key={section._key} {...section} />;
                                case "SanityTextImgR":
                                    return <TextImgR key={section._key} {...section} />;
                                case "SanityImgTwoColContent":
                                    return <ImgTwoColContent key={section._key} {...section} />;
                                case "SanityImgSlider":
                                    return <Carousel key={section._key} {...section} />;
                                case "SanityUnorderedList":
                                    return <UnorderedList key={section._key} {...section} />;
                                case "SanityClimateFacts":
                                    return <ClimateFactsSlider key={section._key} {...section} />;
                                case "SanityEnterCompetition":
                                    return <EnterCompetition key={section._key} {...section} />;
                                case "SanityWorkshopSlider":
                                    return <WorkshopSlider key={section._key} {...section} />;
                                case "SanityCurator":
                                    return <Curator key={section._key} {...section} />;
                                case "SanityFightingClimateChange":
                                    return (
                                        <FightingClimateChange key={section._key} {...section} />
                                    );
                                case "SanityHelpSlider":
                                    return <HelpSlider key={section._key} />;
                                default:
                                    return <p>Nothing to see here</p>;
                            }
                        })}
                    </div>
                </main>
            </Layout>
        </>
    );
};

export default PageTemplate;

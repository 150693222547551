import React from "react";

const Curator = () => {
    return (
        <div className="curator">
            <div className="relative title w-full">
                <h2 className="relative">
                    <span className="impact-title">Climate</span>
                    <span className="impact-title">Change</span>

                    <span className="impact-title">News</span>
                </h2>
            </div>
            <div id="curator-feed" className="container mx-auto xl:w-5/6"></div>
        </div>
    );
};

export default Curator;

import React from "react";
import { graphql } from "gatsby";

export const fragment = graphql`
    fragment UnorderedListSection on SanityUnorderedList {
        _key
        _type
        listHeader
        listItems
    }
`;

const UnorderedList = (props) => {
    console.log(props.listHeader);
    return (
        <div key={props.key} className="list container xl:w-5/6 mx-auto">
            <div className="w-full">
                <strong className="mb-6 block text-lg">{props.listHeader}</strong>
                <ul>
                    {props.listItems.map((item, i) => (
                        <li key={i}>{item}</li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default UnorderedList;

import React from "react";
import { StaticQuery, graphql, Link } from "gatsby";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return <div className={className} style={{ ...style }} onClick={onClick} />;
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return <div className={className} style={{ ...style }} onClick={onClick} />;
}

export default function ClimateFacts() {
    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        arrows: true,
        centerMode: true,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };
    const urlPath = "/ways-to-help/";
    return (
        <StaticQuery
            query={graphql`
                query HelpSliderQuery {
                    allSanityHelp {
                        edges {
                            node {
                                title
                                url {
                                    current
                                }
                            }
                        }
                    }
                }
            `}
            render={(data) => (
                <div className="slider help-slider flex flex-wrap mb-12">
                    <div className="container mx-auto">
                        <div className="relative title w-full">
                            <h2 className="headline-title wcyd relative top-0">
                                <span className="impact-title">What</span>
                                <span className="impact-title">Can</span>
                                <span className="impact-title">You</span>
                                <span className="impact-title">Do</span>
                                <span className="impact-title">To</span>
                                <span className="impact-title">Help</span>
                                <span className="impact-title">?</span>
                            </h2>
                        </div>
                    </div>

                    <div className="w-full">
                        <div className="help-slider-container">
                            <Slider {...settings}>
                                {data.allSanityHelp.edges.map((help) => (
                                    <div className="card help-card p-10">
                                        <h2 className="mt-0">{help.node.title}</h2>
                                        <Link
                                            to={urlPath + help.node.url.current}
                                            className="default-link"
                                        >
                                            Read more {">"}
                                        </Link>
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </div>
                </div>
            )}
        />
    );
}

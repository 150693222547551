import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import StyledLink from "../components/styledlink";
import FormattedText from "../components/portableText";

export const fragment = graphql`
    fragment TextImgR on SanityTextImgR {
        _key
        _type
        _rawContent
        content {
            children {
                text
            }
        }
        title
        id
        image {
            asset {
                url
                altText
                title
                gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
            }
        }
        link {
            buttonLink
            buttonTitle
        }
    }
`;

const TextImgR = (props) => {
    return (
        <div
            key={props.key}
            className="split-image theme-bg section-slope section-slope__top mb-12 md:lg-36"
        >
            <div
                id={props.id ? props.id : null}
                className="container xl:w-5/6 mx-auto flex flex-wrap items-center p-6"
            >
                <div className="w-full sm:w-1/2 p-0 sm:p-6">
                    <h2>{props.title}</h2>
                    {props._rawContent ? <FormattedText blocks={props._rawContent} /> : null}
                    {props.link ? <StyledLink link={props.link} linkText={props.linkText} /> : null}
                </div>
                {props.image ? (
                    <div className="w-full sm:w-1/2 flex align-center justify-center">
                        <GatsbyImage image={props.image.asset.gatsbyImageData} />
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export default TextImgR;

import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import FormattedText from "../components/portableText";

export const fragment = graphql`
    fragment ImgTwoColContent on SanityImgTwoColContent {
        _key
        _type
        id
        _rawContentCol1
        _rawContentCol2
        contentCol1 {
            children {
                text
            }
        }
        contentCol2 {
            children {
                text
            }
        }
        title
        fullwImg {
            asset {
                gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
            }
        }
    }
`;

const ImgTwoColContent = (props) => {
    return (
        <div
            key={props.key}
            id={props.id ? props.id : null}
            className="container flex flex-wrap p-6 mx-auto mb-12 xl:w-5/6"
        >
            <div className="w-full">
                <h2>{props.title}</h2>
            </div>
            {props.fullwImg ? (
                <div className="w-full mb-6">
                    <GatsbyImage image={props.fullwImg.asset.gatsbyImageData} />
                </div>
            ) : null}
            <div className="w-full p-0 pb-6 sm:w-1/2 sm:pb-0 sm:pr-6">
                <FormattedText blocks={props._rawContentCol1} />
            </div>
            <div className="w-full p-0 sm:w-1/2 sm:pl-6">
                <FormattedText blocks={props._rawContentCol2} />
            </div>
        </div>
    );
};

export default ImgTwoColContent;

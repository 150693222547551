import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { Link, graphql } from "gatsby";
import FormattedText from "../components/portableText";

export const fragment = graphql`
    fragment EnterCompetition on SanityEnterCompetition {
        id
        _key
        _rawContent
        content {
            children {
                text
            }
        }
        image {
            asset {
                gatsbyImageData
            }
        }
    }
`;

const EnterCompetition = (props) => {
    return (
        <div
            key={props.key}
            className="enter-competition container xl:w-5/6 mx-auto flex flex-wrap mb-12 mt-36"
        >
            <div className="w-full sm:w-1/2 p-6">
                <h2 className="relative mb-6">
                    <span className="impact-title">Enter</span>
                    <span className="impact-title">One of our</span>
                    <span className="impact-title">Competitions</span>
                </h2>
                <FormattedText blocks={props._rawcontent} />
                <Link to="/competitions" className="default-link">
                    View the competitions we are running {">"}
                </Link>
            </div>
            {props.image ? (
                <div className="w-full sm:w-1/2 flex items-center">
                    <GatsbyImage image={props.image.asset.gatsbyImageData} />
                </div>
            ) : null}
        </div>
    );
};

export default EnterCompetition;

import React from "react";
import { StaticQuery, graphql } from "gatsby";
import Slider from "react-slick";
import Card from "../components/card";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return <div className={className} style={{ ...style }} onClick={onClick} />;
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return <div className={className} style={{ ...style }} onClick={onClick} />;
}

export default function ClimateFacts() {
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: true,
        centerMode: true,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };
    const urlPath = "/workshop/";
    return (
        <StaticQuery
            query={graphql`
                query WorkshopQuery {
                    allSanityWorkshop {
                        edges {
                            node {
                                workshop_url {
                                    current
                                }
                                workshop_date
                                workshop_leader
                                workshop_name
                                workshop_short_desc
                                workshop_description {
                                    children {
                                        text
                                    }
                                }
                                workshop_image {
                                    asset {
                                        gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
                                    }
                                }
                                tags {
                                    project_tag
                                }
                            }
                        }
                    }
                }
            `}
            render={(data) => (
                <Slider {...settings}>
                    {data.allSanityWorkshop.edges.map((workshop) => (
                        <Card
                            class="workshop slide"
                            imagedata={
                                workshop.node.workshop_image
                                    ? workshop.node.workshop_image.asset.gatsbyImageData
                                    : null
                            }
                            alt={workshop.node.workshop_name}
                            title={workshop.node.workshop_name}
                            tags={workshop.node.tags.project_tag}
                            duration="1-2 hours"
                            excerpt={workshop.node.workshop_short_desc}
                            link={urlPath + workshop.node.workshop_url.current}
                            anchor="Read more  >"
                        />
                    ))}
                </Slider>
            )}
        />
    );
}

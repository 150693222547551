import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

export const fragment = graphql`
    fragment ImageBlock on SanityImageBlock {
        _key
        _type
        images {
            image {
                asset {
                    gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
                }
            }
            image_caption
            alt_text
        }
    }
`;

const Image = (props) => {
    let imgCount = props.images.length;
    let columns;
    const imgClass = " image-block--image-container flex flex-col";
    if (imgCount === 1) {
        columns = "w-full" + imgClass;
    } else if (imgCount === 2) {
        columns = "w-full sm:w-1/2" + imgClass;
    } else if (imgCount === 3) {
        columns = "w-full sm:w-1/3" + imgClass;
    }
    return (
        <div className="image-block container xl:w-5/6 mx-auto flex flex-wrap">
            {props.images.map((obj) => (
                <div className={columns}>
                    <GatsbyImage
                        image={obj.image.asset.gatsbyImageData}
                        alt={props.images.alt_text}
                    />
                    <span>{obj.image_caption}</span>
                </div>
            ))}
        </div>
    );
};

export default Image;

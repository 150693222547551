import React from "react";
import { graphql } from "gatsby";

export const fragment = graphql`
    fragment Header on SanityHeader {
        _key
        _type
        sectionHeader
    }
`;

const Header = (props) => {
    return (
        <div className="container xl:w-5/6">
            <div className="w-full">
                <h2 style={{ fontSize: "20px", }}>{props.sectionHeader}</h2>
            </div>
        </div>
    );
};

export default Header;
